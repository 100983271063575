import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { roles, schoolfacilitycategory } from "../../constants/data";
import { TbCategoryFilled } from "react-icons/tb";
import { MdClose } from "react-icons/md";

const Schoolpicker = (props) => {
  const [list, setlist] = useState(props.list);
  const [listvisible, setlistvisible] = useState(false);

  const [searchvalue, setsearchvalue] = useState("");
  var { pickedSchool, setpickedSchool } = props.hook;

  const onChange = (e) => {
    setpickedSchool(undefined);

    if (!listvisible) {
      setlistvisible(true);
    }
    setsearchvalue(e.target.value);
    setlist(
      props.list.filter((it) =>
        it.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    console.log(e.target.value);
  };

  return (
    <>
      <Menu as="div" className="relative my-4 border border-faint">
        <div>
          <p className="font-light m-3 ">
            Select the school you work with currently
          </p>
          <div className="flex bg-faint mx-2 m-2 p-2 rounded  items-center">
            <FaSearch size={15} className="text-customGray " />

            <input
              id="search"
              onChange={onChange}
              value={searchvalue}
              className="  font-light border border-customGray bg-faint outline-0"
              placeholder="Search by name"
            />
          </div>
          {listvisible && (
            <div className="flex flex-col ">
              {" "}
              <MdClose
                onClick={() => {
                  setlistvisible(false);
                }}
                size={30}
                className=" m-1 rounded-full p-1 text-rose-400 self-end"
              />
              <div className="relative bg-customGray overflow-y-scroll max-h-[30vh] w-full p-2">
                {list.map((item) => (
                  <p
                    className="text-primary font-semibold p-2 bg-white mb-1 cursor-pointer "
                    onClick={() => {
                      setsearchvalue(item);
                      setpickedSchool(item);
                      setlistvisible(false);
                    }}
                  >
                    {item}
                  </p>
                ))}
                {list.length < 1 && (
                  <p className="text-black font-light p-2 bg-white mb-1 cursor-pointer text-sm ">
                    School not found
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left z-10 absolute left-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5  h-[300px] overflow-y-scroll overflow-x-clip focus:outline-none">
            {schoolfacilitycategory.map((item) => (
              <Menu.Item>
                {({ active }) => (
                  <p
                    onClick={() => {
                      setpickedSchool(item);
                    }}
                    className=" text-sm font-light w-full text-black p-3 m-2 cursor-pointer truncate hover:bg-customGray "
                  >
                    {item.name}
                  </p>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export { Schoolpicker };
