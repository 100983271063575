import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import { PhotoUpload } from "../generic/PhotoUpload";
import { usePhotoUpload } from "../../Hooks/usePhotoUploadhook";
import { toast } from "react-toastify";
import { onUploaddocument } from "../../Utils/Servicess/user";
import { useState } from "react";
import Spinner from "../generic/Spinner";

const StepTwo = (props) => {
  const passportuploadhook = usePhotoUpload();
  const [loading, setLoading] = useState(false);
  const IDuploadhook = usePhotoUpload();

  const { control, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    if (!passportuploadhook.file) {
      toast.error("Passport Photograph is required");
      return;
    }

    // if (!IDuploadhook.file) {
    //   toast.error("ID is required");
    //   return;
    // }

    //Upload id, return urls for each set to formpostdata the register user
    //Confirm

    var idpostdata = new FormData();
    idpostdata.append("id", IDuploadhook.file);
    idpostdata.append("email", props.postdata.email);
    idpostdata.append("name", "id");
    var passportpostdata = new FormData();
    passportpostdata.append("passport", passportuploadhook.file);
    passportpostdata.append("email", props.postdata.email);
    passportpostdata.append("name", "Passport");

    setLoading(true);

    if (IDuploadhook.file) {
      var formdata = { iddoc: "", passport: "" };
      var iduploadres = await onUploaddocument(idpostdata);

      if (iduploadres.success) {
        formdata = { ...formdata, iddoc: iduploadres.url };
      } else {
        toast.error("Could not upload document");
        setLoading(false);
        return;
      }
    }

    var passportupres = await onUploaddocument(passportpostdata);

    if (passportupres.success) {
      formdata = { ...formdata, passport: passportupres.url };
      setLoading(false);
    } else {
      toast.error("Could not upload document");
      setLoading(false);

      return;
    }

    props.setpostdata({ ...props.postdata, ...formdata });

    props.nextStep();
    props.setCurrentStepIndex(2);
  };

  return (
    <div className="bg-white">
      <Spinner loading={loading} />

      <p className="m-3 font-semibold text-primary">Documents</p>

      <div className=" flex flex-col ">
        <PhotoUpload
          id="passport"
          title="Passport Photograph"
          hook={passportuploadhook}
        />
        <hr />
        <PhotoUpload
          id="id"
          isdocument={true}
          title="ID (Optional)"
          hook={IDuploadhook}
        />
        <div className=" flex gap-4 ">
          <Button
            outlined
            text="Previous"
            onBtnClick={() => {
              props.setCurrentStepIndex(0);
              props.previousStep();
            }}
          />
          <Button text="Next" onBtnClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </div>
  );
};

export { StepTwo };
