import { useForm } from "react-hook-form";
import InputField from "../generic/Inputfield";
import { numReg, textReg } from "../../Utils/regex";
import Button from "../generic/Button";
import { onUpdateUser } from "../../Utils/Servicess/user";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Rolepicker } from "../Register/RolePicker";
import { usePickerhook } from "../../Hooks/usePickerhook";
import { ListPicker } from "../ui/ListPicker";
import { levels, subjects } from "../../constants/data";

const EditProfileDetails = (props) => {
  var authuser = useSelector((state) => state.userStore);
  const { control, handleSubmit } = useForm();
  const rolepickedhook = usePickerhook();
  const teachingcat = usePickerhook();
  const teachingclass = usePickerhook();
  const teachingsub = usePickerhook();

  const submit = async (data) => {
    if (!rolepickedhook.pickedRole) {
      toast.error("Select a role");
      return;
    }

    const postdata = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: authuser.email,
      phone: data.phone,
      role: rolepickedhook.pickedRole,
      teachingcat: teachingcat.pickedRole,
      subject: teachingsub.pickedRole,
      class: teachingclass.pickedRole,
      role: rolepickedhook.pickedRole,
    };

    props.loading(true);

    try {
      var res = await onUpdateUser(postdata);
      if (res.message) {
        toast.success(res.message);
       

        props.showaddmodal(false);
      }

      props.loading(false);

      props.refresh()
    } catch (e) {
      console.log(e);
      props.loading(false);
    }
  };
  return (
    <div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex z-50 bg-[#00000073] justify-center items-center ">
      <div className="bg-white rounded min-w-[30vw] max-h-[80vh] p-10 overflow-y-scroll ">
        <h2 className="text-primary">Edit Profile details</h2>
        <InputField
          name="firstname"
          title="First name"
          def_value={authuser.firstname}
          placeholder="First name"
          control={control}
          rules={{
            required: "Firstname is required",
            pattern: {
              value: textReg,
              message: "Invalid Firstname",
            },
          }}
        />
        <InputField
          name="lastname"
          title="Last name"
          def_value={authuser.lastname}
          placeholder="Last name"
          control={control}
          rules={{
            required: "Lastname is required",
            pattern: {
              value: textReg,
              message: "Invalid Lastname",
            },
          }}
        />
        <InputField
          isDisabled={true}
          name="email"
          title="Email"
          def_value={authuser.email}
          placeholder=""
          control={control}
        />
        <InputField
          name="phone"
          title="Phone number"
          def_value={authuser.phone}
          placeholder="Enter phone number"
          control={control}
          rules={{
            required: "Phone is required",
            pattern: {
              value: numReg,
              message: "Invalid Phone number",
            },
          }}
        />
        <hr />

        <Rolepicker hook={rolepickedhook} />

        {rolepickedhook.pickedRole == "Teacher" && (
          <ListPicker
            hook={teachingcat}
            title="Teaching category"
            list={["Secondary", "Primary", "Nusery"]}
          />
        )}
        {teachingcat.pickedRole == "Secondary" && (
          <ListPicker
            title="Subject specialization"
            hook={teachingsub}
            list={subjects}
          />
        )}

        {(teachingcat.pickedRole == "Primary" ||
          teachingcat.pickedRole == "Nusery") && (
          <ListPicker title="Class" hook={teachingsub} list={levels} />
        )}

        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.showaddmodal(false);
            }}
          />
          <Button width={55} text="Save" onBtnClick={handleSubmit(submit)} />
        </div>
      </div>
    </div>
  );
};
export { EditProfileDetails };
