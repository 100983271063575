import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { FaAngleDown, FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { roles } from "../../constants/data";

const Rolepicker = (props) => {
  var { pickedRole, setPickedRole } = props.hook;

  return (
    <>
      <Menu as="div" className="relative my-4">
        <div>
          <p className="text-xs mb-2 ">What is your job title?</p>
          <Menu.Button className="  px-4 py-2  flex text-sm bg-gray-50 w-full  rounded    ">
            <div className="flex flex-items items-center  w-full gap-4">
              <div className=" flex justify-between w-full  items-center p-1">
                <p className="text-xs font-light">
                  <FaUserAlt className="text-black inline" size={18} />{" "}
                  {pickedRole ? pickedRole : "Select your job title"}{" "}
                </p>{" "}
                <FaAngleDown color="#777777" />
              </div>

              <div className="flex items-center"></div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-left z-10 absolute left-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {roles.map((item) => (
              <Menu.Item>
                {({ active }) => (
                  <p
                    onClick={() => {
                      setPickedRole(item.roleid);
                    }}
                    className=" text-sm font-light w-full text-black p-3 m-2 cursor-pointer truncate hover:bg-customGray "
                  >
                    {item.name}
                  </p>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export { Rolepicker };
