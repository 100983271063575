import { useEffect, useState } from "react";
import { logo } from "../../constants/imports";
import { StepFour } from "../../Components/Register/StepFour";
import Button from "../../Components/generic/Button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputField from "../../Components/generic/Inputfield";
import { emailReg } from "../../Utils/regex";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { onLogin } from "../../Utils/Servicess/auth";
import Spinner from "../../Components/generic/Spinner";

const Login = () => {
  const [postdata, setpostdata] = useState({});
  const [loading, setLoading] = useState();
  const authuser = useSelector((state) => state.userStore);

  const navigate = useNavigate();
  const dispather = useDispatch();
  const [currentStepIndexState, setCurrentStepIndex] = useState(0);
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    console.log(postdata);
  }, [postdata]);

  useEffect(() => {
    if (authuser.firstname) {
      navigate("/dashboard");
      return;
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    let formData = {
      email: data.email,
      password: data.password,
    };
    try {
      setLoading(true);
      let res = await onLogin(formData);
      toast(res ? res.message : " An error occured");

      if (res.user) {
        dispather({ type: "set-user", payload: res.user });

        navigate("/dashboard");
        localStorage.setItem(`staffUserData`, JSON.stringify(res?.user));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
    setLoading(false);
  };

  return (
    <div className="w-full flex md:p-4 justify-center flex-col  items-center  ">
      <img  src={logo} className="m-2 w-[130px] " />
      <Spinner loading={loading} />

      <div className="bg-white rounded  flex flex-col p-4 md:px-8 md:w-[30%]">
        <h1 className="mb-3 w-full text-center text-lg ">
          Schoolverse Staff Login{" "}
        </h1>
        <h3 className="mb-3 w-full text-center font-light ">
          Your profile managed from one place
        </h3>
        <hr className=" m-2" />
        <div className="w-full px-6">
          <InputField
            name="email"
            title="Email Address"
            placeholder="Enter Email Address"
            control={control}
            rules={{
              required: "Email Address is required",
              pattern: {
                value: emailReg,
                message: "Invalid Email Address",
              },
            }}
          />

          <InputField
            name="password"
            title="Password"
            placeholder="Enter password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            type={"password"}
          />

          <div className="w-full flex justify-between items-center">
            {" "}
            <p className="text-[13px] text-center text-primary">
              <input type="checkbox" /> Remember me{" "}
            </p>{" "}
            <Link to="/resetpassword">
                <p className=" text-primary text-[13px] cursor-pointer">
                  Forgot password
                </p>
              </Link>
          </div>
          <Button text="Login" onBtnClick={handleSubmit(onSubmit)} />
          <p className="text-primary text-[12px] w-full text-center">
            Don't have an account?{" "}<br/>
            <Link className="font-semibold" to="/login">
              Create an account
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export { Login };
