import axios from "axios";
import { onSendEmail } from "./Servicess/user";

function removeNonLetters(str) {
    return str.replace(/[^a-zA-Z]/g, '');
  }
  
  const SendVerificationlink= async (email, user_name)=>{


    var data = {
      service_id: 'service_frfposm',
      template_id: 'template_iz1glx8',
      user_id: 'Dj6VhRIdHNx3OoTdd',
      template_params: {
          'email': email,
          'user_name': user_name,
          'verify_link': `https://schoolverse.osunstate.gov.ng/staff/verify/${email}`,
          'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
      }
  };
  
  try{
  
  
    var res =await axios.post('https://api.emailjs.com/api/v1.0/email/send',data)
    console.log(res,"Response verify")
  }
  
  catch(e){
  
  
    console.log(e)
  }
  
  }


  const Sendchangepasswordlink= async (email)=>{

    var postdata ={
      to: email,
      content:` Hello, follow this link to reset your password https://schoolverse.osunstate.gov.ng/staff/changepass/${email}`,
     "subject":"Reset Password"
    }
    try{
    
    var res =await onSendEmail(postdata)
    
    }
    
    catch(e){
    
    console.log(e)
    
    }

 
  
  }



  // const Sendchangepasswordlink= async (email)=>{


  //   var data = {
  //     service_id: 'service_zwm7j84',
  //     template_id: 'template_9z0p1wp',
  //     user_id: '0cZO0FZi303slJcZa',
  //     template_params: {
  //         'email': email,
          
  //         'link': `https://staff.schoolversedata.com/changepass/${email}`,
  //         'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
  //     }
  // };
  
  // try{
  
  
  //   var res =await axios.post('https://api.emailjs.com/api/v1.0/email/send',data)
  //   console.log(res,"Response verify")
  // }
  
  // catch(e){
  
  
  //   console.log(e)
  // }
  
  // }


  
  export{removeNonLetters, Sendchangepasswordlink,SendVerificationlink}