import StepWizard from "react-step-wizard";
import { StepTwo } from "../../Components/Register/StepTwo";
import { StepThree } from "../../Components/Register/StepThree";
import { StepOne } from "../../Components/Register/StepOne";
import WizardStepIndicator from "wizard-step-indicator-react";
import { useEffect, useState } from "react";
import { logo } from "../../constants/imports";
import { StepFour } from "../../Components/Register/StepFour";
import { Link } from "react-router-dom";

const RegisterStaff = () => {
  const [postdata, setpostdata] = useState({});

  const [currentStepIndexState, setCurrentStepIndex] = useState(0);

  useEffect(()=>{

console.log(postdata)

  },[postdata])

  return (
    <div className="w-full flex md:p-4 justify-center flex-col  items-center  ">
      <img src={logo} className="m-2 w-[130px] " />

      <div className="bg-white rounded  flex flex-col p-4 md:px-8  md:w-[30%]">
        <h1 className="mb-3 w-full text-center text-lg ">
          Schoolverse Staff Registration{" "} 
        </h1>
        <h3 className="mb-3 w-full text-center font-light ">
          Your profile managed from one place  
        </h3>  
        <hr className=" m-2" />
        <WizardStepIndicator
          stepsCount={4}
          currentStepIndex={currentStepIndexState}
          onClick={(index) => {
            setCurrentStepIndex(index);
          }}
        />

        <StepWizard>
       
          <StepOne
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
          <StepTwo
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
         <StepThree
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
          <StepFour
            setCurrentStepIndex={setCurrentStepIndex}
            postdata={postdata}
            setpostdata={setpostdata}
          />
        </StepWizard>
        <p className="text-primary text-[13px] w-full text-center">
            Already have an account?{" "}
            <Link className="font-semibold" to="/">
              Login
            </Link>
          </p>
      </div>

    </div>
  );
};

export { RegisterStaff };
