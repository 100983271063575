import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Spinner from "../../Components/generic/Spinner";
import { Navbar } from "../../Components/ui/Navbar";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import { all } from "axios";
import { VerificationBadge } from "../../Components/dashboard/VerificationBadge";
import Footer from "../../Components/ui/Footer";

const Dashboard = () => {
  const authuser = useSelector((state) => state.userStore);
  var location = useLocation();
  const dispatcher = useDispatch();
  var navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  });

  useEffect(() => {
    // if (isDesktopOrLaptop) {
    //   console.log("isdesktop");
      if (!authuser?.firstname) {
        navigate("/login");
        return;
      }
    // } else {
    //   console.log("not desktop");
    //   navigate("/mobile");
    // }
  }, [authuser?.school]);
  return (
    <div className="w-full h-[85vh]    flex">
      <Spinner loading={loading} />

      <div className="w-[100vw] h-[100vh] mt-1 bg-[#F3F2F7]  ">
        <Navbar /><br/>
        <div className=" bg-white flex p-3  items-center justify-between ">
        <p className="text-[#171717b5] capitalize font-semibold  m-4 self-start">
          Staff profile dashboard
        </p>
        <div className=" ">
      <VerificationBadge verified={authuser.verified}/>

      </div>
      
        </div>

        <Outlet className=" bg-primary  w-full h-[100vh]" />
        <Footer/>
      </div>
    </div>
  );
};

export { Dashboard };
