const user = localStorage.getItem(`staffUserData`);

const userobj = JSON.parse(user ?? "{}");
console.log(userobj);

const defaultState = {
  staffSchool: {},
  userStore: userobj,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {

    case "set-school":
      return{...state, staffSchool:{...action.payload} }

    case "set-user":
      return { ...state, userStore: { ...action.payload } };
    default:
      return { ...state };
  }
};

export { reducer };
