import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import { onAddpastWork, onUpdateUser } from "../../Utils/Servicess/user";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { usePickerhook } from "../../Hooks/usePickerhook";
import { useEffect, useState } from "react";
import {
  onGetAllSchool,
  onUpdatestaffschool,
} from "../../Utils/Servicess/school";

import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";

import { useSchoolPickerhook } from "../../Hooks/useSchoolpickerhook";
import { Schoolpicker } from "../Register/Schoolpicker";
import InputField from "../generic/Inputfield";
import { addressRegex, textReg } from "../../Utils/regex";

const ChangeStaffSchool = (props) => {
  var authuser = useSelector((state) => state.userStore);
  const school_ = useSelector((state) => state.staffSchool);

  const [allschools, setallschools] = useState([]);
  const schoolpickerhook = useSchoolPickerhook();

  const [startdatenew, setstartdatenew] = useState(new Date());
  const [startdateprev, setstartdateprev] = useState(new Date());

  const teachingsub = usePickerhook();
  const { control, handleSubmit } = useForm();

  const submit = async (data) => {
    if (!schoolpickerhook.pickedSchool) {
      toast.error("Select a valid registered school");
      return;
    }

    const postdata = {
      email: authuser.email,
      school: schoolpickerhook.pickedSchool,
    };

    props.loading(true);

    try {
      var res = await onUpdatestaffschool(postdata);
      if (res.message) {
        toast.success(res.message);
        //toast("Log out and re-login for updates to reflect");
        props.showaddmodal(false);
      }

      props.loading(false);
    } catch (e) {
      console.log(e);
      props.loading(false);
    }
    const pastworkpostdata = {
      email: authuser.email,
      schoolname: authuser.schhool,
      record: {
        role: authuser.role,
        address: school_.address,
        date: `${startdateprev.toDateString()} - ${startdatenew.toDateString()}`,
      },
    };

    try {
      var res = await onAddpastWork(pastworkpostdata);
      if (res.message) {
        toast.success(res.message);

        props.showaddmodal(false);
      }

      props.loading(false);

      props.refresh();
    } catch (e) {
      console.log(e);
      props.loading(false);
    }
    props.refresh();
  };

  const init = async () => {
    props.loading(true);
    var response = await onGetAllSchool();
    props.loading(false);
    setallschools(Object.keys(response));
  };

  useEffect(() => {
    init();
  }, [props.depends]);

  return (
    <div className=" fixed w-[100vw] h-[100vh] top-0 left-0 flex z-50 bg-[#00000073] justify-center items-center ">
      <div className="bg-white rounded min-w-[30vw] max-h-[80vh] p-10 overflow-y-scroll ">
        <h2 className="text-primary">Change school</h2>

        <Schoolpicker list={allschools} hook={schoolpickerhook} />

        <hr />

        {/* Date picker for startdateprev */}
        <div className="my-4">
          <p className="text-xs mb-2 ">Start date for previous school</p>
          <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
            <MdDateRange className="p-2 " size={30} />

            <DatePicker
              className="font-light"
              selected={startdateprev}
              onChange={(date) => setstartdateprev(date)}
            />
          </div>
        </div>

        {/* Date picker for startdatenew */}

        <div className="my-4">
          <p className="text-xs mb-2 ">Start date for new school</p>
          <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
            <MdDateRange className="p-2 " size={30} />

            <DatePicker
              className="font-light"
              selected={startdatenew}
              onChange={(date) => setstartdatenew(date)}
            />
          </div>
        </div>

        <div className="w-full  flex  gap-3 justify-end">
          <Button
            width={40}
            outlined
            text="Cancel"
            onBtnClick={() => {
              props.showaddmodal(false);
            }}
          />
          <Button width={55} text="Save" onBtnClick={handleSubmit(submit)} />
        </div>
      </div>
    </div>
  );
};
export { ChangeStaffSchool };
