import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logo, logolight } from "../../constants/imports";
import { IoMdNotifications } from "react-icons/io";

 
const Navbar = () => {
  var authuser = useSelector((state) => state.userStore);
  var dispatcher=useDispatch()
  var navigate = useNavigate()

 
  
const logout = () => {
    localStorage.clear();
    dispatcher({type:"set-user", payload:{}})
    navigate("/");

  };

  

  return (
    <div className="p-4 h-[10vh] flex justify-between bg-white w-full  items-center  shadow z-40">
      
      
 
      <img   src={logolight} className=" m-2 w-40" />
<div className="flex justify-center items-center">

<IoMdNotifications
          className="text-[#005A82] active:shadow-lg shadow"
          onClick={() => {
            navigate("/dashboard/messages");
          }}
          size={30}
        />


<Menu as="div" className="  self-center">
            <div>
              <Menu.Button className=" p-1 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                   
      <div className="bg-faint p-1 self-center rounded-xl flex-center items-center">
        <p className="text-[12px] inline font-light mr-2 ">
          {" "}
          Hi,{authuser?.firstname}
        </p>
        <img
          className="rounded-[25px] inline"
          height="30px"
          width="30px"
          src={`${authuser.passport}`}
        />
      </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <div className="p-3 bg-customBlack flex rounded-lg ">

                    <div>

                      <img src="/images/avatar.png" alt="" />


                    </div>
                    <div className='   text-white'>  <p className="text-sm my-2 font-light ">

                      {authuser && authuser.firstname}
                    </p>

                      <p className="text-xs font-light mr-2 truncate    ">

                        {authuser && authuser.email}
                      </p>
                      <p className="text-xs mr-2 text-ellipsis overflow-hidden  font-light  ">

{authuser && authuser.phone}
</p>
                    </div>

                  </div>
                </Menu.Item>

           
   
                {/* <Menu.Item>
                  {({ active }) => (
                    <button
                      className="active:bg-gray-200 rounded-sm   text-sm font-semibold block px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      
                      onClick={()=>{
                        navigate('/dashboard/settings')
           }}
                    >
Settings

                    </button>
                  )}
                </Menu.Item> */}
                <hr/>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className="active:bg-gray-200 block text-sm font-semibold rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
                      
                      onClick={logout}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>


              </Menu.Items>
            </Transition>
          </Menu>
</div>
     



    </div>
  );
};

export { Navbar };
