import { useState } from "react"

const useSchoolPickerhook =()=>{
const [pickedSchool, setpickedSchool] =useState()




return{pickedSchool,setpickedSchool}
}

export{useSchoolPickerhook}