import axios from "axios";
import { baseURL } from "../URL";

const onLogin = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/loginstaff`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
export { onLogin };
