import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import InputField from "../generic/Inputfield";
import { Schoolpicker } from "./Schoolpicker";
import { useSchoolPickerhook } from "../../Hooks/useSchoolpickerhook";
import { Rolepicker } from "./RolePicker";
import { usePickerhook } from "../../Hooks/usePickerhook";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import { ListPicker } from "../ui/ListPicker";
import { levels, subjects } from "../../constants/data";

const StepThree = (props) => {
  const schoolpickerhook = useSchoolPickerhook();
  const rolepickedhook = usePickerhook();
  const teachingcat = usePickerhook();
  const teachingclass = usePickerhook();
  const teachingsub = usePickerhook();

  const [allschools, setallschools] = useState([]);

  const { control, handleSubmit } = useForm();

  const init = async () => {
    var response = await onGetAllSchool();
    setallschools(Object.keys(response));
  };

  useEffect(() => {
    init();
  }, []);
  const onSubmit = (data) => {
    if (!schoolpickerhook.pickedSchool) {
      {
        toast.error("Select a valid registered school");
        return;
      }
    }
    if (!rolepickedhook.pickedRole) {
      {
        toast.error("Select a your job title");
        return;
      }
    }

    props.nextStep();
    props.setCurrentStepIndex(3);

    var postdata = {
      schhool: schoolpickerhook.pickedSchool,
      teachingcat: teachingcat.pickedRole,
      subject: teachingsub.pickedRole,
      class: teachingclass.pickedRole,
      role: rolepickedhook.pickedRole,
    };
    props.setpostdata({ ...props.postdata, ...postdata });
  };

  return (
    <div className="bg-white">
      <p className="m-3 text-primary font-semibold">School details</p>

      <Schoolpicker list={allschools} hook={schoolpickerhook} />
      <hr />
      <Rolepicker hook={rolepickedhook} />

      {rolepickedhook.pickedRole == "Teacher" && (
        <ListPicker
          hook={teachingcat}
          title="Teaching category"
          list={["Secondary", "Primary", "Nusery"]}
        />
      )}
      {teachingcat.pickedRole == "Secondary" && (
        <ListPicker
          title="Subject specialization"
          hook={teachingsub}
          list={subjects}
        />
      )}

      {(teachingcat.pickedRole == "Primary" ||
        teachingcat.pickedRole == "Nusery") && (
        <ListPicker title="Class" hook={teachingsub} list={levels} />
      )}

      <div className=" flex gap-4 ">
        <Button
          outlined
          text="Previous"
          onBtnClick={() => {
            props.setCurrentStepIndex(1);
            props.previousStep();
          }}
        />
        <Button text="Next" onBtnClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};

export { StepThree };
