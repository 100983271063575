import { onconfirmuserExist } from '../../Utils/Servicess/user';
import { emailReg, numReg, textReg, urlregex } from '../../Utils/regex'
import Button from '../generic/Button'
import InputField from "../generic/Inputfield"

import { toast } from "react-toastify";

import {useForm} from 'react-hook-form'
import Spinner from '../generic/Spinner';
import { useState } from 'react';

const StepOne = (props) => {
    const {control, handleSubmit} =useForm()
    const [loading,setLoading]=useState()
    const onSubmit =async (data)=>{
      setLoading(true)

var postdata ={email:data.email}
      const res = await onconfirmuserExist(postdata)
      //if user doesn't exist  proceed with the flow
      if(res.success){
        setLoading(false)

      }
      else{
        setLoading(false)
      toast.error("User with this email already exist")
      
      return
      }
        props.nextStep()
        props.setCurrentStepIndex(1)

        props.setpostdata({...props.postdata, ...data})


    }

  return < div className='bg-white'>
            <Spinner loading={loading} />

 <p className="m-3 font-semibold text-primary">Basic information</p>
 <InputField
        name="firstname"
        title="First Name"
        placeholder="Enter Firstname"
        control={control}
        rules={{
          required: "Firstname is required",
          pattern: {
            value: textReg,
            message: "Invalid Firstname",
          },
        }}
      />
        <InputField
        name="lastname"
        title="Last Name"
        placeholder="Enter Lastname"
        control={control}
        rules={{
          required: "Lastname is required",
          pattern: {
            value: textReg,
            message: "Invalid Lastname",
          },
        }}
      />
      <InputField
        name="email"
        title="Email Address"
        placeholder="Enter Email Address"
        control={control}
        rules={{
          required: "Email Address is required",
          pattern: {
            value: emailReg,
            message: "Invalid Email Address",
          },
        }}
      />


<InputField
        name="phone"
        title="Phone number"
        placeholder="Enter phone number"
        control={control}
        rules={{
          required: "Phone is required",
          pattern: {
            value: numReg,
            message: "Invalid Phone number",
          },
        }}
      />

  <div className=" flex gap-4 ">
   
  <Button text="Next" onBtnClick={handleSubmit(onSubmit)} />
  </div>
  
  
   
  </div>;
};
export {StepOne}