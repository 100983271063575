import axios from "axios";
import { baseURL } from "../URL";

const onconfirmuserExist = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/confirmuser`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onGetstaffuser = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getstaffuser`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};
const onUpdateUser = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/updatestaffuser`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const Registerstaff = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/registerstaff`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onUploaddocument = async (formdata) => {
  try {
    const response = await axios.post(`${baseURL}/uploaddocument`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onAddpastWork = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/addpastwork`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onGetpastWork = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getpastwork`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onChangeuserPass = async (postData) => {
  try {
      const response = await axios.post(`${baseURL}/staff/changepassword`, postData);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
}

const onGetAllstaffs= async (postData) => {
  try {
      const response = await axios.get(`${baseURL}/getstaffs`);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
};
const onGetSchoolClasses = async (postData) => {
  try {
    const response = await axios.post(`${baseURL}/getschoolclass`, postData);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === undefined) {
      throw error.message;
    } else {
      throw error?.response?.data?.message;
    }
  }
};

const onGetAllMessages= async (postData) => {
  try {
      const response = await axios.post(`${baseURL}/getmessages`,postData);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
};


const onSendEmail = async (postData) => {
  try {
      const response = await axios.post(`${baseURL}/sendemailverification`, postData);
      return response.data;
  } catch (error) {
      if (error?.response?.data?.message === undefined) {
          throw error.message
      } else {
          throw error?.response?.data?.message;
      }
  }
}

export {
  onconfirmuserExist,
  onGetpastWork,
  onGetAllMessages,
  onAddpastWork,onSendEmail
  ,
  onGetSchoolClasses,
  onGetAllstaffs,
  onChangeuserPass,
  onUpdateUser,
  onGetstaffuser,
  Registerstaff,
  onUploaddocument,
};
