import { baseURL } from "../URL"; 
import axios from 'axios'

const onSendMessage = async (Postdata) => {
    try {
      const response = await axios.post(`${baseURL}/sendmessage`, Postdata);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  };

  export{onSendMessage}