import { MdDashboard } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { CiReceipt } from "react-icons/ci";
import { FaCertificate, FaComment } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { FcRating } from "react-icons/fc";
import { FaMapLocation } from "react-icons/fa6";

const itemstyle = { size: 20, color: "#fff" };
const categories = [
  { name: "Nusery", id: "nusery" },

  { name: "Primary", id: "pprimary" },
  { name: "Secondary", id: "secondary" },
];


const subjects = [
  "English",
  "Maths",
  "Civic Ed",
  "Biology",
  "Chemistry",
  "Physics",
  "Literature",
  "Geography",
  "Agri Sci",
  "Govt",
  "History",
  "Economics",
  "CRS",
  "IRS",
  "Computer",
  "Further Maths",
  "French",
  "Yoruba",
  "Igbo",
  "Hausa",
  "Arts",
  "Music",
  "Home Ec",
  "PHE",
  "Social St",
  "Data Proc",
  "Catering",
  "Garment Mk",
  "Auto Mech",
  "Welding",
  "Electrical",
  "Bricklaying",
  "Painting",
  "Plumbing",
  "Carpentry",
  "Woodwork",
  "Upholstery",
  "Printing",
  "Beauty",
  "Tourism",
  "Leather",
  "Animal Husb",
  "Fisheries",
  "Marketing",
  "Store Keep"
];

const roles = [
  {
    name: "Principal",
    roleid: "Principal",
  },
  {
    name: "Teacher",
    roleid: "Teacher",
  },
  {
    name: "Proprietor",
    roleid: "Proprietor",
  },
  {
    name: "Headmaster/Headmistress",
    roleid: "Headmaster/Headmistress",
  },
];

const States = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
const LGAs = [
  "Atakumosa West",
  "Atakumosa East",
  "Ayedaade",
  "Ayedire",
  "Boluwaduro",
  "Boripe",
  "Ede South",
  "Ede North",
  "Egbedore",
  "Ejigbo",
  "Ife North",
  "Ife South",
  "Ife-Central",
  "Ife-East",
  "Ifelodun",
  "Ila",
  "Ilesa-East",
  "Ilesa-West",
  "Ifedayo",
  "Ife-East area office",
  "Irepodun",
  "Irewole",
  "Isokan",
  "Iwo",
  "Obokun",
  "Odo-Otin",
  "Ola Oluwa",
  "Olorunda",
  "Oriade",
  "Orolu",
  "Osogbo",
];

const schoolfacilitycategory = [
  { name: "Classroom", type: "classroom" },

  { name: "Playground", type: "playground" },
  { name: "Sport Facility", type: "sports" },
  { name: "Labouratory", type: "lab" },
  { name: "Mosque", type: "mosque" },
  { name: "Chapel", type: "chapel" },
  { name: "ICT", type: "ict" },
  { name: "Boarding Facility", type: "boarding" },
];

const schooladminitems = [
  {title:'Certificates', path:'/admin/certificates'}
,   {title:'Fees', path:'/admin/fees'},
{title:'Facilities', path:'/admin/facilities'},
{title:'Payments', path:'/admin/payments'},
{title:'Map location', path:'/admin/mapadmin'},
{title:'Review', path:'/admin/payments'},
{title:'Rate school', path:'/admin/payments'}
];

const sidebaritems = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: (
      <MdDashboard
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },

  {
    name: "Facilities",
    path: "/dashboard/facilities",
    icon: (
      <IoIosSettings
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Fees",
    path: "/dashboard/fees",
    icon: (
      <CiReceipt
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Certificates",
    path: "/dashboard/certificates",
    icon: (
      <FaCertificate
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Map",
    path: "/dashboard/map",
    icon: (
      <FaMapLocation
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    )
  },
  {
    name: "Review",
    path: "/dashboard/review",
    icon: (
      <FaComment
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    )
  },
  {
    name: "Rating",
    path: "/dashboard/rating",
    icon: (
      <FcRating
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Payments",
    path: "/dashboard/payments",
    icon: (
      <MdOutlinePayment
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
];

const levels = [
  "Kindergarten",
  "Pre-Reception",
  "Reception",
  "Nursery One",
  "Nursery Two",
  "Primary One",
  "Primary Two",
  "Primary Three",
  "Primary Four",
  "Primary Five",
  "Primary Six"
];


export {
  sidebaritems,
  States,
  LGAs,
  roles,
  subjects,
  categories,
  schooladminitems,
  schoolfacilitycategory,
  levels
};


  