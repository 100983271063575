import { useSelector } from "react-redux";
import MapComponent from "../../Components/school/MapItem";
import { useEffect, useState } from "react";
import { useLocationHook } from "../../Hooks/useLocationHook";
import { SchoolRating } from "../../Components/dashboard/SchoolRating";
import { onGetSchoolReview } from "../../Utils/Servicess/school";

const SchoolDeails = () => {
    var locationhook=useLocationHook()
    const school_ = useSelector((state) => state.staffSchool);

    const [reviewItems, setReviewItems] = useState([]);
    const [rating,setrating]=useState(0)

    const getSelectedschoolReview = async () => {
      setLoading(true)
      var formdata = { schoolname: school_.schoolname };
      var res = await onGetSchoolReview(formdata);
      setLoading(false)
      console.log(res.review_items, "....///....");
      setReviewItems(res.review_items);
    };
  
    useEffect(() => {
      getSelectedschoolReview();
    }, []);

    useEffect(()=>{


    
      let sum=0
      let percent=0
          reviewItems?.map((k)=>{
      sum=sum+k.score
      
          }  )
      
          percent=Math.ceil((sum/80)*100)
          setrating(percent)
        },[reviewItems])
      
      
const [loading, setLoading]=useState()
    const userschool=useSelector((state)=>state.staffSchool)
  return (
    <div className=" bg-white">

      <p className="p-3 font-semibold">School Rating</p>
      <SchoolRating percent={rating} num={Math.ceil((rating/100)*5)}/>
{/* Staff school map location */}
      {/* <MapComponent
        userschool={userschool}
        setLoading={setLoading}
        Hook={locationhook}
        isViewonly={true}
      /> */}
    </div>
  );
};
export { SchoolDeails };
