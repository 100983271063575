import logo from "./logo.svg";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./State/store";
import "./index.css";
import "./output.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RegisterStaff } from "./Pages/Auth/Registration";
import { Login } from "./Pages/Auth/Login";
import { Dashboard } from "./Pages/Userprotected/Dashboard";
import { AccountDashboard } from "./Pages/Userprotected/Accountdashbaord";
import Footer from "./Components/ui/Footer";
import "react-datepicker/dist/react-datepicker.css";

import { Requestchange } from "./Pages/Auth/RewuestReset";
import { ResetPass } from "./Pages/Auth/ResetPass";
import Notificatons from "./Pages/Userprotected/Notificatons";
 
function App() {
  return (
    <div className="bg-customGray h-screen overflow-y-scroll">
      <Provider store={store}>
        <Router 
            //  basename="/staff"
        >
          <ToastContainer
            toastStyle={{ backgroundColor: "white", color: "black" }}
          />

          <Routes>
            <Route path="/login" element={<RegisterStaff />} />
            <Route path="/" element={<Login />} />
            <Route path="/resetpassword" element={<Requestchange />} />
            <Route path="/changepass/:userId" element={<ResetPass />} />
            <Route path="/dashboard" element={<Dashboard />} >
            
            <Route path="" element={<AccountDashboard />} />

            <Route path="messages" element={<Notificatons />} />

            </Route>
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
